<template>
  <ClFormRow :label="label" :toolTipText="tooltip" name="rewardTypeId" ref="rewardTypeRef">
    <template #content>
      <multiselect
        @search-change="onInput"
        v-model="selectedRewardType"
        :options="rewardTypeOptions"
        :hide-selected="true"
        :selectLabel="selectLabel"
        :block-keys="['Tab', 'Enter']"
        required
        label="name"
        track-by="id"
        class="custom-multi-select zq--multiselect"
        :searchable="searchable"
        placeholder="Type here..."
      >
        <span slot="noOptions"></span>
      </multiselect>
      <img
        src="../../assets/icons/search.svg"
        width="16"
        height="16"
        alt="search"
        class="zq--multiselect-search-icon"
      />
    </template>
  </ClFormRow>
</template>

<script>
import { formConfig } from "@/config";
import { mapActions } from "vuex";

export default {
  name: "ZqSelectRewardTypeId",
  props: {
    value: [Array, String],
    label: String,
    tooltip: String,
    css: [String, Object],
    multiple: { type: Boolean, default: false },
    searchable: { type: Boolean, default: true },
  },
  data() {
    return {
      selectedRewardType: null,
      selectLabel: formConfig.selectLabel,
      rewardTypeOptions: [],
      initOptions: null,
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions('rewardTypes', ['handleGetRewardTypesByQuery']),
    async initialize() {
      this.rewardTypeOptions = [];

      this.initOptions = await this.handleGetRewardTypesByQuery({
        queryRequest: {
          sortBy: [
            {
              queryField: 'name',
              order: 'Asc',
            },
          ],
          limit: 20
        }
      });

      this.rewardTypeOptions = this.initOptions;
    },
    async onInput(val) {
      if (val.length >= 1) {
        this.rewardTypeOptions = await this.handleGetRewardTypesByQuery({
          queryRequest: {
            multiFields: [
              {
                queryFields: ['name'],
                queryValue: val
              }
            ],
            sortBy: [
              {
                queryField: 'name',
                order: 'Asc',
              },
            ],
          }
        })
      }
      if (val.length === 0) {
        this.rewardTypeOptions = this.initOptions ?? [];
      }
    }
  },
  watch: {
    selectedRewardType: {
      deep: true,
      handler(val) {
        if (val) {
          this.$refs.rewardTypeRef.$el.classList.remove('zq-invalid')
        }

        this.$emit('input', this.selectedRewardType.id)
      }
    }
  }
}
</script>

<style scoped>

</style>